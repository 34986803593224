
import { VueAgile } from 'vue-agile'
export default {
  name: 'CarouselSix',
  components: {
    VueAgile,
  },
  props: {
    mainSlider: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mainSliderActive: 0,
      sliderOptions: {
        fade: true,
        navButtons: false,
        dots: false,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 1024,
          },
        ],
      },
    }
  },
  computed: {
    activeDescription() {
      return this.mainSlider[this.mainSliderActive]?.description || ''
    },
  },
  methods: {
    setActiveToMain({ currentSlide }) {
      this.mainSliderActive = currentSlide
    },
  },
}
